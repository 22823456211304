import BandwagonDotCom from "../../components/BandwagonDotCom"
import React from "react"
import TeamMember from "../../components/TeamMember"

import { StaticImage } from "gatsby-plugin-image"

const JoePowers = () => <BandwagonDotCom>
  <TeamMember
        headshot=<StaticImage src="../../images/joe-powers-headshot.jpg"
                alt="Joe Powers" />
        name="Joe Powers"
        position="Software Engineer, TPM"
  >
      <p>
        As a former GE design engineer and proud Georgia Tech alum, Joe brings
        creative and elegant full stack solutions to our clients, fueled by a
        passion for live music and events. Coming from the world of
        turbomachinery and industrial gas turbine design, where 40+ million
        dollar machines have to perform reliably or the lights turn off, precise
        and robust solutions are Joe’s specialty. His experience using data and
        analysis to uncover the truth of the matter at hand, and his perspective
        as a musician and performer makes him a perfect fit for our company.
      </p>
      <h3>Fun Fact</h3>
      <hr/>
      <p>
        Joe is the lead guitarist and harmony vocalist in the band Wolfgang
        Wallace, and his drummer is the reason he pursued a career in coding.
      </p>
      <h3>Why I love Bandwagon</h3>
      <hr/>
      <p>
        Problem solving at incredible speed, creating deeper and more meaningful
        connections between fans and the teams/artists/places they love, an
        inspiring team of brilliant and kind people, there are just too many
        reasons to list!
      </p>
  </TeamMember>
</BandwagonDotCom>;

export default JoePowers
